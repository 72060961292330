<template>
    <div class="page1">
        <EForm
                :formColumns="formColumns"
                :rowSize="4"
                :optionsBtn="true"
                :actionBtn="false"
                :formData="searchForm"
                ref="form"
                @onSearch="onSearch"
                :searchFlag="true"
                :exportShow="false"
                ></EForm>
        <EButton @click="dialogShow" type="primary">新增</EButton>
        <ETable :tableTitle="tableTitle" :tableData="tableData" :needPagination="false">
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton @click="editDialog(scope.row)" type="text">修改</EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 @handleClick="saveData">
            <EForm :formColumns="dialogFormColumns"
                   :labelPosition="labelPosition"
                   :rowSize="1"
                   :optionsBtn="false"
                   :labelWidth="labelWidth"
                   :actionBtn="false"
                   :formData="form"
                   :formRules="formRules"
                   ref="form"

                   @handleClose="cancelDialog"
                   :searchFlag="false"
            ></EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {mapActions} from 'vuex'
  export default {
    name: 'Login',
    data() {
      return {
        formColumns: [
          {
            title: '字典编号',
            type: 'text',
            property: 'code',
            placeHolder: '请输入',
            show: true,
          },
          {
            title: '字典名称',
            type: 'text',
            property: 'dictValue',
            placeHolder: '请输入',
            show: true,
          },],
        searchForm: {
          dictValue:'',
          code:'',
        },
        tableTitle: [
          {
            label: '字典编号',
            prop: 'code',
          },
          {
            label: '字典名称',
            prop: 'dictValue',
          },
          {
            label: '字典备注',
            prop: 'remark',
          },
        ],
        tableData: [],
        dialogVisible: false,
        title: '添加字典',
        form: {
          code:'',
          dictValue:'',
          remark:'',
          id:''
        },
        dialogFormColumns:[
          {
            title:'字典编号',
            type:'text',
            property:'code',
            placeholder:'最多可录入15字',
            require:true,
            show:true,
          },
          {
            title:'字典名称',
            type:'text',
            property:'dictValue',
            placeholder:'最多可录入15字',
            require:true,
            show:true,
          },
          {
            title:'字典备注',
            type:'textarea',
            property:'remark',
            placeholder:'最多可录入500字',
            require:true,
            show:true
          }
        ],
        formRules:{
          code:[vxRule(true, "", "blur", "请输入字典编号")],
          dictValue:[vxRule(true, "", "blur", "请输入字典名称"),
            vxRule(true, val=>{
              return {result: val.length<=15, errMsg: "最多可录入15字"}})],
          remark:[vxRule(true, '',"blur", "请输入备注"),
            vxRule(true, val=>{
              return {result: val.toString().length<=500, errMsg: "最多可录入500字"}})],
        },
        labelWidth:'100px',
        labelPosition:'right'
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog,EButton,EForm},
    beforeMount() {

    },
    methods: {
      ...mapActions(['setTips']),
      onSearch(){
        this.searchForm.page=1
        this.getData()
      },
      cancelDialog(){
        this.dialogVisible=false
        this.title='添加字典'
        this.form={
          code:'',
          dictValue:'',
          remark:'',
          id:''
        }
        this.$refs.form.$refs.form.resetFields()
        this.dialogFormColumns[0].disabled=false
      },
      dialogShow(){
        this.dialogFormColumns[0].disabled=false
        this.dialogVisible=true
      },
      async getData() {
        let res = await Http.dictList(this.searchForm)
        this.tableData = res.data
        // this.setTips(this.tableData)
      },
      saveData(){
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid && !this.error) {
            this.addBtn()
          } else {
            return false;
          }
        });
      },
      async addBtn() {
        let res = await Http.dictSubmit(this.form)
        if(res.code==200){
          this.$message.success(res.msg)
          this.cancelDialog()
          this.getData()
        }

      },
      editDialog(data){
        this.title='编辑字典'
        this.form.code=data.code
        this.form.remark=data.remark
        this.form.dictValue=data.dictValue
        this.form.id=data.id
        this.dialogVisible=true
        this.dialogFormColumns[0].disabled=true
      },
    },

  }
</script>


<style lang="scss" scoped>
    .login-container {
        min-height: 100%;
        width: 100%;
        background-color: #fff;
        overflow: hidden;

        .login-form {
            position: relative;
            width: 520px;
            max-width: 100%;
            padding: 160px 35px 0;
            margin: 0 auto;
            overflow: hidden;
            box-sizing: border-box;
        }

        .tips {
            font-size: 14px;
            color: #fff;
            margin-bottom: 10px;

            span {
                &:first-of-type {
                    margin-right: 16px;
                }
            }
        }

        .svg-container {
            padding: 6px 5px 6px 15px;
            color: #999;
            vertical-align: middle;
            width: 30px;
            display: inline-block;
        }

        .title-container {
            position: relative;

            .title {
                font-size: 26px;
                color: #fff;
                margin: 0px auto 40px auto;
                text-align: center;
                font-weight: bold;
            }
        }

        .show-pwd {
            position: absolute;
            right: 10px;
            top: 7px;
            font-size: 16px;
            color: #2d3a4b;
            cursor: pointer;
            user-select: none;
        }
    }
</style>

